import React from "react";
import styles from "./Cosultant.module.scss";

// Properties
import properties from "@/properties.json";

// Animation
import { Slide, Fade } from "react-reveal";
import { Link } from "react-router-dom";

const Cosultant = () => {
  return (
    <section className="page-part">
      <div className={styles.Cosultant}>
        <div className={styles.overlay}>
          <div className={styles.content}>
            <div className={styles.image}>
              <Slide bottom delay={700}>
                <Fade bottom delay={700}>
                  <img
                    src={require(`@/assets/${properties.appearence.logo}`)}
                    alt={properties.app_info.name}
                  />
                </Fade>
              </Slide>
            </div>
            <Slide bottom>
              <Fade bottom>
                <h1>AMSTONE CONSULTING</h1>
                <h2>“IT STARTS BY MAKING YOUR CHALLENGES OUR CHALLENGES”</h2>
                <Link to="/consulting">LET’S START OUR JOURNEY TOGETHER</Link>
              </Fade>
            </Slide>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cosultant;
