import React from "react";
import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.title}>CONTACT US</div>
        <div className={styles.contact}>
          {/* T: <a href="tel:6462066666">6462066666</a> */}
          {/* <br /> */}
          <a href="mailto: Info@amstoneig.com"> Info@amstoneig.com</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
