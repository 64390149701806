import React from "react";
import styles from "./ContactUs.module.scss";

// Components
import Form from "@/components/ContactUs/Form";

// Data
import { contactUsInfo } from "@/data/contact-info";

// Assets
import WeAreAmstone from "@/assets/images/png/we-are-amstone.png";

// Hooks
import useSmoothScroller from "@/hooks/useSmoothScroller";

// Animation
import { Slide, Fade } from "react-reveal";

const ContactUs = () => {
  useSmoothScroller();

  return (
    <div className="scroll-container">
      <div className={styles.ContactUs}>
        <div className={styles.branchesBanner}>
          <div className="container">
            <Slide bottom>
              <Fade bottom>
                <section>
                  <h1>Contact Us</h1>
                  <p>
                    Amstone are International, we are wherever we need to be to
                    best serve our clients. From our 3 main locations based
                    centrally across the globe we can reach far and wide.
                  </p>

                  <div className={styles.locations}>
                    {contactUsInfo.map((country) => (
                      <span key={country.country}>{country.country}.</span>
                    ))}
                  </div>

                  <div>
                    {/* <a href="tel:6462066666">T: 6462066666</a> */}
                    <a href="mailto:INFO@AMSTONEIG.COM">INFO@AMSTONEIG.COM</a>
                  </div>
                </section>
              </Fade>
            </Slide>
          </div>
        </div>
        <div className={styles.slogan}>
          <img
            src={WeAreAmstone}
            className={styles.WeAreAmstone}
            alt="We Are Amstone"
          />
        </div>
        <div className={styles.contactForm}>
          <Form />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
