import React from "react";
import styles from "./Header.module.scss";

// Properties
import properties from "@/properties.json";

// Animation
import { Slide, Fade } from "react-reveal";

const Header = () => {
  return (
    <div className={styles.Header}>
      <div className={styles.overlay}>
        <div className={styles.content}>
          <Slide bottom>
            <Fade bottom>
              <h1>
                AMSTONE <br /> CONSULTING
              </h1>
            </Fade>
          </Slide>
          <div className={styles.image}>
            <Slide bottom delay={700}>
              <Fade bottom delay={700}>
                <img
                  src={require(`@/assets/${properties.appearence.logo}`)}
                  alt={properties.app_info.name}
                />
              </Fade>
            </Slide>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
