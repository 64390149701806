import React from "react";
import styles from "./BannerTwo.module.scss";

// Animation
import { Slide, Fade } from "react-reveal";

const BannerTwo = () => {
  return (
    <div className={styles.BannerTwo}>
      <div className={styles.quote}>
        <Slide bottom>
          <Fade bottom>
            <p>“OUR CLIENTS ALL OVER THE WORLD RELY ON US”</p>
          </Fade>
        </Slide>
      </div>
    </div>
  );
};

export default BannerTwo;
