export const contactUsInfo = [
  {
    id: 1,
    country: "EGYPT",
    phone: "6462066666",
    email: "egypt@amstoneig.com",
  },
  {
    id: 2,
    country: "USA",
    phone: "6462066666",
    email: "usa@amstoneig.com",
  },
  {
    id: 3,
    country: "UAE",
    phone: "6462066666",
    email: "uae@amstoneig.com",
  },
  {
    id: 4,
    country: "UK",
    phone: "6462066666",
    email: "uk@amstoneig.com",
  },
  {
    id: 5,
    country: "FRANCE",
    phone: "6462066666",
    email: "france@amstoneig.com",
  },
  {
    id: 6,
    country: "HUNGARY",
    phone: "6462066666",
    email: "greece@amstoneig.com",
  },
];