import React from "react";
import styles from "./OurSolutuins.module.scss";

// Packages
import { useNavigate } from "react-router-dom";

// Data
import { ourSolutionsData } from "@/data/solutions";

const OurSolutuins = () => {
  const navigate = useNavigate();

  const renderedSolutuins = ourSolutionsData.map((solution, index) => {
    return (
      <div
        className={styles.solution}
        key={solution.id}
        style={{
          backgroundImage: `url(${require(`@/assets/${solution.image}`)})`,
        }}
        onClick={() =>
          navigate(
            solution.page ? `/${solution.page}` : `/solutions/${solution.id}`
          )
        }
      >
        <div className={styles.overlay}>
          <div className={styles.content}>
            <span>{solution.tag}</span>
            <h3>{solution.title}</h3>
          </div>
        </div>
      </div>
    );
  });

  return (
    <section className="page-part">
      <div className={styles.ourSolutions}>{renderedSolutuins}</div>
    </section>
  );
};

export default OurSolutuins;
