import { useEffect, useState } from "react";
import Scrollbar from "smooth-scrollbar";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const useSmoothScroller = () => {
  const [scrollbarI, setScrollbar] = useState(null);

  useEffect(() => {
    const scrollbar = Scrollbar.init(
      document.querySelector(".scroll-container"),
      {
        damping: 0.08,
        delegateTo: document,
      }
    );
    setScrollbar(scrollbar);

    ScrollTrigger.scrollerProxy(document.querySelector(".scroll-container"), {
      scrollTop(value) {
        if (arguments.length) {
          scrollbar.scrollTop = value;
        }
        return scrollbar.scrollTop;
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
    });

    scrollbar.addListener(ScrollTrigger.update);
    ScrollTrigger.defaults({
      scroller: document.querySelector(".scroll-container"),
    });

    // Apply only on desktop
    if (window.innerWidth < 768) return;

    const pageParts = gsap.utils.toArray(".page-part:not(:last-of-type)");
    pageParts.forEach((part) => {
      ScrollTrigger.create({
        trigger: part,
        start: "top top",
        pin: true,
        pinSpacing: false,
        markers: false,
      });
    });
  }, []);

  return { scrollbarI };
};

export default useSmoothScroller;
