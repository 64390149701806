import React, { useEffect, useRef } from "react";
import styles from "./MoreInfo.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Animation
import { Slide, Fade } from "react-reveal";

// Assets
import BlackBg from "@/assets/images/jpg/black-bg.jpg";

gsap.registerPlugin(ScrollTrigger);

const MoreInfo = () => {
  const section = useRef(null);
  const flag = useRef(null);
  const text = useRef(null);

  useEffect(() => {
    // Apply only on desktop
    if (window.innerWidth < 768) return;
    
    gsap.to(flag.current, {
      scrollTrigger: {
        trigger: section.current,
        start: "bottom bottom",
        scrub: true,
      },
      y: "-=390px",
    });

    gsap.to(text.current, {
      scrollTrigger: {
        trigger: section.current,
        start: "bottom bottom",
        scrub: true,
      },
      opacity: 0,
    });
  }, []);

  return (
    <section className="page-part" ref={section}>
      <div className={styles.moreInfo}>
        <div className="container">
          <div className={styles.content} ref={text}>
            <div className={styles.head}>
              <Slide bottom>
                <Fade bottom>
                  <div>
                    WE BELIEVE IN THE POWER OF INGENUITY TO BUILD POSITIVE HUMAN
                    FUTURE IN A TECHNOLOGY-DRIVEN WORLD.
                  </div>
                </Fade>
              </Slide>
            </div>
            <div className={styles.slogan}>
              <Slide bottom delay={1000}>
                <Fade bottom delay={1000}>
                  <div>
                    AS STRATEGIES, TECHNOLOGIES AND INNOVATION MERGE IN EVER
                    CHANGING ENVIRONMENT; WE CREATE OPPORTUNITIES FROM
                    COMPLEXITY TO SIMPLICITY.
                  </div>
                </Fade>
              </Slide>
            </div>
            <div className={styles.paragraph}>
              <Slide bottom delay={1000}>
                <Fade bottom delay={1000}>
                  Our diverse teams of experts combine innovative thinking and
                  knowledge in breakthrough technologies to progress further and
                  faster. Our clients in the Middle East and North Africa adapt,
                  transform and work together to achieve enduring results. We
                  are specialists in defense, security, transport and logistics.
                  We operate globally from offices across Egypt, USA, UAE, UK,
                  France, Greece, Poland and Ukraine.
                </Fade>
              </Slide>
            </div>
          </div>
        </div>
        <div className={styles.blackBg} ref={flag}>
          <Slide bottom>
            <Fade bottom>
              <img src={BlackBg} alt="BlackBg" />
            </Fade>
          </Slide>
        </div>
      </div>
    </section>
  );
};

export default MoreInfo;
