import React, { useEffect, useRef } from "react";
import styles from "./About.module.scss";
import gsap, { Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Animation
import { Slide, Fade } from "react-reveal";

// Assets
import MaskedImage from "@/assets/videos/masked-bg.mp4";
import Mountains from "@/assets/images/png/sky.png";
import Plane from "@/assets/images/png/plane.png";

gsap.registerPlugin(ScrollTrigger);

const About = () => {
  const plane = useRef(null);
  const mountains = useRef(null);
  const section = useRef(null);

  useEffect(() => {
    // Apply only on desktop
    if (window.innerWidth < 768) return;

    gsap.to(plane.current, {
      scrollTrigger: {
        trigger: section.current,
        start: "bottom bottom",
        scrub: 1,
      },
      top: "-=315px",
      ease: Power3.easeInOut,
      duration: 2,
    });

    gsap.to(mountains.current, {
      scrollTrigger: {
        trigger: section.current,
        start: "top 40%",
        scrub: 1,
      },
      y: "-215",
      ease: Power3.easeInOut,
      duration: 2,
    });
  }, []);

  return (
    <section className="page-part" ref={section}>
      <div className={styles.about}>
        <div className="container">
          <div className={styles.aboutText}>
            <Slide bottom>
              <Fade bottom>
                <div>The First</div>
                <div>Line of Defense</div>
              </Fade>
            </Slide>
            <video
              autoPlay
              loop
              muted
              playsInline
              className={styles.introVideo}
            >
              <source src={MaskedImage} type="video/mp4" />
            </video>
          </div>

          <div className={styles.aboutDescription}>
            <Slide bottom delay={1000}>
              <Fade bottom delay={1000}>
                <div>
                  AT AMSTONE, OUR ADVANCED DEFENCE TECHNOLOGY PROTECTS PEOPLE
                  NATIONAL SECURITY, AND KEEPS CRITICAL INFORMATION AND
                  INFRASTRUCTURE SECURE. WE SEARCH FOR NEW WAYS TO PROVIDE OUR
                  CUSTOMERS WITH A COMPETITIVE EDGE ACROSS THE AIR, MARITIME AND
                  LAND.
                </div>
              </Fade>
            </Slide>
          </div>
        </div>
        <div className={styles.bottomSection}>
          <Slide bottom duration={4000}>
            <Fade bottom duration={4000}>
              <div className={styles.planeWrapper}>
                <img
                  src={Plane}
                  className={styles.plane}
                  alt="Plane"
                  ref={plane}
                />
              </div>
            </Fade>
          </Slide>
          <Slide bottom duration={2500}>
            <Fade bottom duration={2500}>
              <div className={styles.mountainWrapper}>
                <img
                  src={Mountains}
                  className={styles.mountains}
                  alt="Mountains"
                  ref={mountains}
                />
              </div>
            </Fade>
          </Slide>
        </div>
      </div>
    </section>
  );
};

export default About;
