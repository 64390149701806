import React from "react";
import styles from "./MobileMenu.module.scss";
import { NavLink } from "react-router-dom";

const MobileMenu = ({ openMobileMenu, setOpenMobileMenu }) => {
  return (
    <div className={styles.menuWrapper}>
      <div
        className={`${styles.mobileMenu} ${openMobileMenu && styles.opemMenu}`}
      >
        <div className={styles.nav}>
          <NavLink onClick={() => setOpenMobileMenu(false)} to="/consulting">
            Consulting
          </NavLink>
          <NavLink onClick={() => setOpenMobileMenu(false)} to="/">
            Home
          </NavLink>
          <NavLink onClick={() => setOpenMobileMenu(false)} to="/solutions">
            Solutions
          </NavLink>
          <NavLink onClick={() => setOpenMobileMenu(false)} to="/contact-us">
            Contact
          </NavLink>
        </div>
      </div>
      <div
        className={`${styles.menuOverlay} ${openMobileMenu && styles.opemMenu}`}
        onClick={() => setOpenMobileMenu(false)}
      />
    </div>
  );
};

export default MobileMenu;
