import React from "react";
import { Link } from "react-router-dom";
import styles from "./OurSolutionsBanner.module.scss";

// Animation
import { Fade } from "react-reveal";

const OurSolutionsBanner = () => {
  return (
    <section className="page-part">
      <div className={styles.ourSolutionsBanner}>
        <div className={styles.backgroundText}>
          <h1>LAND. SEA. AIR. LAND. SEA. AIR.</h1>
        </div>

        <div className="container">
          <div className={styles.row}>
            <div className={styles.col6}>
              <div className={styles.head}>
                <h2>
                  <Fade distance={"50px"} duration={700} right>
                    OUR
                  </Fade>
                  <Fade distance={"50px"} duration={700} delay={200} right>
                    SOLUTIONS
                  </Fade>
                </h2>
                <span>
                  <Fade distance={"50px"} duration={700} delay={400} right>
                    EXCELLENCE AS
                  </Fade>
                  <Fade distance={"50px"} duration={700} delay={600} right>
                    STANDARD
                  </Fade>
                </span>
              </div>
            </div>
            <div className={styles.col6}>
              <div className={styles.content}>
                <div className={styles.firstParagraph}>
                  <Fade distance={"50px"} duration={700} delay={900} right>
                    <p>
                      OFFERING WIDE RANGE OF WELL-DESIGNED, FUNCTIONAL ADVANCED
                      DEFENCE TECHNOLOGY WEAPONS THAT HIGH-RISES MILITARIES
                      CAPABILITIES; IN ORDER TO BE STRONG AND MODERNISED TO
                      COUNTER ALL KINDS OF OF THREATS AS THEY ARISE.
                    </p>
                  </Fade>
                </div>
                <div className={styles.secondParagraph}>
                  <Fade distance={"50px"} duration={700} delay={1500} right>
                    <p>
                      We provide unparalled defence solutions for almost every
                      situation, we
                    </p>
                  </Fade>
                  <Fade distance={"50px"} duration={700} delay={1600} right>
                    <p>
                      alwayswork to our clients best interests and will tailor
                      make packages to
                    </p>
                  </Fade>
                  <Fade distance={"50px"} duration={700} delay={1700} right>
                    <p>suit their exacting needs and requirments.</p>
                  </Fade>
                </div>
                <Fade distance={"50px"} duration={700} delay={1800} right>
                  <Link to="/solutions">EXPLORE</Link>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurSolutionsBanner;
