import React from "react";
import { Outlet } from "react-router-dom";

// Components
import TopBar from "@/components/shared/TopBar";

const Layout = () => {
  return (
    <div>
      <TopBar />
      <Outlet />
    </div>
  );
};

export default Layout;
