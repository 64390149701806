// Properties
import properties from "@/properties.json";

export const getColorsFromProperties = () => {
  document.documentElement.style.setProperty(
    "--primary-color",
    properties.appearence.primary_color,
    "important"
  );
  document.documentElement.style.setProperty(
    "--primary-dimmed-color",
    properties.appearence.primary_color_dimmed,
    "important"
  );
  document.documentElement.style.setProperty(
    "--secondary-color",
    properties.appearence.secondary_color,
    "important"
  );
};
