import React from "react";
import styles from "./Sections.module.scss";

// Animation
import { Slide, Fade } from "react-reveal";

// Assets
import sectionOneImage from "@/assets/images/jpg/SectionOneImage.jpg";
import sectionTwoImage from "@/assets/images/jpg/SectionTwoImage.jpg";

const Sections = () => {
  return (
    <div className={styles.Sections}>
      <div className={styles.section}>
        <Slide bottom>
          <Fade bottom>
            <div
              className={styles.image}
              style={{ backgroundImage: `url(${sectionOneImage})` }}
            />
          </Fade>
        </Slide>
        <div className={styles.content}>
          <Slide bottom delay={200}>
            <Fade bottom delay={200}>
              <h1>
                “BECAUSE WE KNOW WHAT IT <br /> TAKES AND IT’S WHAT WE DO”
              </h1>
            </Fade>
          </Slide>

          <Slide bottom delay={400}>
            <Fade bottom delay={400}>
              <p>
                Since our first contract, we have earned a reputation for
                competence, reliability and professionalism with governments,
                investors, financial institutions, manufacturers and
                defense-related businesses and organizations. Our track record
                isn’t just a success breeds success story, it’s the result of
                tackling our clients’ challenges as if they were our own.
              </p>
              <p>
                The knowledge and tools we bring to the table are nothing
                without a collaborative approach in solving problems and
                realizing the potential for improved performance, efficiency,
                and profitability. That and our ability to continuously innovate
                and evolve with the industry, considering all its drivers, is
                one guarantee for results in the form of durable improvements.
                The other is the fact that our clients can depend on us not only
                for solid recommendations, but also on targeted and hands-on
                support in implementing theory into practice.
              </p>
            </Fade>
          </Slide>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.content}>
          <Slide bottom delay={200}>
            <Fade bottom delay={200}>
              <h1>
                “YOU NAME THE CHALLENGE, <br /> WE’LL DELIVER”
              </h1>
            </Fade>
          </Slide>
          <Slide bottom delay={400}>
            <Fade bottom delay={400}>
              <p>
                Our core assets are our diverse consultant experts’ team who are
                not only strong in methodological competence, but also have a
                vast proven history of practical experience in their respective
                area of expertise. Many of our consultants are qualified defense
                masters and experts in aviation project management, Six Sigma
                and defense contracts. This allows us to support your business
                by taking over the role that is most suitable for the specific
                situation and the challenges you face, also enables us to
                continuously keep ahead of the market in identifying and
                acquiring potential opportunities.
              </p>
              <p>
                The functional and business expertise of our consultants
                supported by our methodological knowledge enables us to act as a
                strategic advisor or a functional specialist to help your
                employees implement practicable solutions. The leadership
                experience of our more senior staff equips them with the
                necessary skills for the position of project manager or – if
                required – shadow manager. We also take on the role of mediator
                to ensure successful change management.
              </p>
            </Fade>
          </Slide>
        </div>
        <Slide bottom>
          <Fade bottom>
            <div
              className={styles.image}
              style={{ backgroundImage: `url(${sectionTwoImage})` }}
            />
          </Fade>
        </Slide>
      </div>
    </div>
  );
};

export default Sections;
