import React from "react";
import styles from "./Header.module.scss";

// Properties
import properties from "@/properties.json";

// Assets
import IntroVideo from "@/assets/videos/AMSTONE-HOME-VIDEO.mp4";

// Icons
import { arrowDownIcon } from "@/helpers/icons";

// Constants
import { WINDOW_HEIGHT } from "@/helpers/const";

// Hooks
import useSmoothScroller from "@/hooks/useSmoothScroller";

const Header = () => {
  const { scrollbarI } = useSmoothScroller();

  const scrollToNextSection = () => {
    scrollbarI.scrollTo(0, WINDOW_HEIGHT, 3000);
  };

  const scrollToThirdSection = () => {
    scrollbarI.scrollTo(0, WINDOW_HEIGHT * 2, 3000);
  };

  return (
    <section className="page-part">
      <div className={styles.header}>
        <video autoPlay loop muted playsInline className={styles.introVideo}>
          <source src={IntroVideo} type="video/mp4" />
        </video>
        <div className={styles.overlay}>
          <div className="container">
            <div className={styles.content}>
              <img
                className={styles.logo}
                src={require(`@/assets/${properties.appearence.logo}`)}
                alt={properties.app_info.name}
              />
              <div className={styles.companyName}>
                AMSTONE INTERNATIONAL GROUP
              </div>
              <div className={styles.companySlogan}>
                SECURITY THROUGH <br /> TECHNOLOGY
              </div>
              <a
                href="/"
                className={styles.explore}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToThirdSection();
                }}
              >
                Explore
              </a>
              <div
                className={styles.scrollDownContainer}
                onClick={() => scrollToNextSection()}
              >
                <div
                  className={styles.scrollDown}
                  onClick={() => scrollToNextSection()}
                >
                  {arrowDownIcon(30, 30)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
