import React from "react";
import styles from "./Banner.module.scss";

// Animation
import { Slide, Fade } from "react-reveal";

const Banner = () => {
  return (
    <div className={styles.Banner}>
      <div className={styles.overlay}>
        <div className="container">
          <p>
            <Slide bottom>
              <Fade bottom>
                We provide unparalled defence solutions for almost every
                situation, we alwayswork to our clients best interests and will
                tailor make packages to suit their exacting needs and
                requirments.
              </Fade>
            </Slide>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
