import React from "react";
import ReactDOM from "react-dom/client";

// Toaster
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// State Management
import { Provider } from "react-redux";
import store from "./store";

// Styiling
import "./index.scss";

// Main App
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
    <ToastContainer position="top-right" />
  </Provider>
);
