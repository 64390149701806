import React from "react";
import styles from "./ClientChart.module.scss";

// Animation
import { Slide, Fade } from "react-reveal";

// Assets
import clientChart from "@/assets/images/png/ClientChart.png";

const ClientChart = () => {
  return (
    <div className={styles.ClientChart}>
      <div className="container">
        <Slide bottom>
          <Fade bottom>
            <img src={clientChart} alt="ClientChart" />
          </Fade>
        </Slide>
      </div>
    </div>
  );
};

export default ClientChart;
