import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

// Actions
import { updateLoading } from "@/store/actions";

// Layout
import Layout from "@/Layout";

// Pages
import Home from "@/pages/Home";
import ContactUs from "@/pages/ContactUs";
import Solutions from "@/pages/Solutions";
import MobileDetection from "@/pages/MobileDetection";
import Consulting from "@/pages/Consulting";

// Components
import DocumentHead from "@/components/DocumentHead";
// import PlayMusic from "@/components/shared/PlayMusic";

// Helpers
import { getColorsFromProperties } from "@/helpers/functions";

const App = () => {
  const dispatch = useDispatch();

  setTimeout(() => {
    dispatch(updateLoading(false));
  }, 8000);

  useEffect(() => {
    getColorsFromProperties();
  }, []);

  return (
    <div className="Amstone-Eg">
      <DocumentHead />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="contact-us">
              <Route index element={<ContactUs />} />
              <Route path=":contactId" element={<ContactUs />} />
            </Route>
            <Route path="solutions">
              <Route index element={<Solutions />} />
              <Route path=":solutionId" element={<Solutions />} />
            </Route>
            <Route path="mobile-detection" element={<MobileDetection />} />
            <Route path="consulting" element={<Consulting />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
