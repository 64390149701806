import React from "react";
import styles from "./BannerOne.module.scss";

// Animation
import { Slide, Fade } from "react-reveal";

const BannerOne = () => {
  return (
    <div className={styles.BannerOne}>
      <div className={styles.quote}>
        <Slide bottom delay={300}>
          <Fade bottom delay={300}>
            <p>“IT STARTS BY MAKING YOUR CHALLENGES OUR CHALLENGES”</p>
          </Fade>
        </Slide>
      </div>

      <div className={styles.cover} />

      <div className={styles.content}>
        <div className="container">
          <div className={styles.p}>
            <Slide bottom delay={100}>
              <Fade bottom delay={100}>
                Over the course of our 10 years journey, Amstone International
                Group has become the go-to partner for solving problems,
                improving performance, and gearing up for the future. It takes
                more than extensive business expertise and experience to explain
                this kind of success. It takes constant innovation and a
                collaborative approach towards solving problems and realizing
                potentials
              </Fade>
            </Slide>
          </div>

          <div className={styles.p}>
            <Slide bottom delay={200}>
              <Fade bottom delay={200}>
                Amstone Consulting began operations based on a simple
                proposition: to take the expertise and experience that come from
                operating an innovative group specializing in all aspects of
                defense and aviation industry and apply them towards creating
                value throughout the global defense industry, by our worldwide
                offices across USA, UAE, UK, France, Greece, Egypt, and Poland.
              </Fade>
            </Slide>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerOne;
