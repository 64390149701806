import React from "react";
import styles from "./Header.module.scss";

// Animation
import { Fade } from "react-reveal";

const Header = () => {
  return (
    <section className="page-part">
      <div className={styles.ourSolutionsBanner}>
        <div className={styles.backgroundText}>
          <h1>LAND. SEA. AIR. LAND. SEA. AIR.</h1>
        </div>

        <div className="container">
          <div className={styles.row}>
            <div className={styles.col6}>
              <div className={styles.head}>
                <h2>
                  <Fade distance={"50px"} duration={700} right>
                    OUR SOLUTIONS
                  </Fade>
                </h2>
                <span>
                  <Fade distance={"50px"} duration={700} delay={400} right>
                    EXCELLENCE AS STANDARD
                  </Fade>
                </span>
              </div>
            </div>
            <div className={styles.col6}>
              <div className={styles.content}>
                <div className={styles.firstParagraph}>
                  <Fade distance={"50px"} duration={700} delay={900} right>
                    <p>
                      OFFERING WIDE RANGE OF WELL-DESIGNED, FUNCTIONAL ADVANCED
                      DEFENCE TECHNOLOGY WEAPONS THAT HIGH-RISES MILITARIES
                      CAPABILITIES; IN ORDER TO BE STRONG AND MODERNISED TO
                      COUNTER ALL KINDS OF OF THREATS AS THEY ARISE.
                    </p>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
