import React from "react";
import styles from "./MobileDetection.module.scss";

// Componenets
import Footer from "@/components/shared/Footer";

// Hooks
import useSmoothScroller from "@/hooks/useSmoothScroller";

// Assets
import MobileDetectionImg from "@/assets/images/jpg/MobileDetection.jpg";
import MobileDetection2Img from "@/assets/images/jpg/MobileDetection2.jpg";

// Animation
import { Slide, Fade, Zoom } from "react-reveal";

const MobileDetection = () => {
  useSmoothScroller();

  return (
    <div className="scroll-container">
      <div className={styles.MobileDetection}>
        <div className={styles.header}>
          <div className="container">
            <div className={styles.text}>
              <Slide bottom>
                <Fade bottom>
                  <span>MDS</span>
                </Fade>
              </Slide>
              <Slide bottom delay={100}>
                <Fade bottom delay={100}>
                  <h1>MOBILE DETECTION</h1>
                </Fade>
              </Slide>
              <Slide bottom delay={200}>
                <Fade bottom delay={200}>
                  <p>
                    REGARDLESS OF THEIR IDEOLOGY, TERRORISTS WILL USE THE TOOLS
                    AT THEIR DISPOSAL TO CREATE FEAR AND MEDIA COVERAGE.
                  </p>
                </Fade>
              </Slide>
            </div>

            <Zoom bottom delay={300}>
              <img src={MobileDetectionImg} alt="MobileDetection" />
            </Zoom>
          </div>
        </div>

        <div className={styles.downloadBrochure}>
          <div className="container">
            <div className={styles.row}>
              <div className={styles.image}>
                <Slide bottom>
                  <Fade bottom>
                    <img src={MobileDetection2Img} alt="MobileDetection2" />
                  </Fade>
                </Slide>
              </div>
              <div className={styles.text}>
                <Slide bottom delay={200}>
                  <Fade bottom delay={200}>
                    <div className={styles.phrase}>
                      FOR <br />
                      SNIFFING OF <br />
                      CBRNE AI & <br />
                      VIRTUAL <br />
                      REALITY <br />
                      SIMULATION
                    </div>
                  </Fade>
                </Slide>
                <Slide bottom delay={400}>
                  <Fade bottom delay={400}>
                    <a href="/">DOWNLOAD BROCHURE</a>
                  </Fade>
                </Slide>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default MobileDetection;
