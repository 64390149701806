import React from "react";
import styles from "./Home.module.scss";

// Components
import Header from "@/components/Home/Header";
import About from "@/components/Home/About";
import MoreInfo from "@/components/Home/MoreInfo";
import OurSolutionsBanner from "@/components/Home/OurSolutionsBanner";
import OurSolutions from "@/components/Home/OurSolutions";
import Cosultant from "@/components/Home/Cosultant";
import InternationalReach from "@/components/shared/InternationalReach";

// Layout
import Footer from "@/components/shared/Footer";

const Home = () => {
  return (
    <div>
      <div className={`scroll-container ${styles.Home}`}>
        <Header />
        <About />
        <MoreInfo />
        <OurSolutionsBanner />
        <OurSolutions />
        <Cosultant />
        <InternationalReach />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
