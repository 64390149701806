import React, { useState, createRef, useEffect } from "react";
import styles from "./Tabs.module.scss";

// Packages
import { useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Data
import { solutions } from "@/data/solutions";

// Assets
import Arrow from "@/assets/images/svg/arrow.svg";

const Tabs = ({ scrollbar }) => {
  const { solutionId } = useParams();

  const [activeTab, setActiveTab] = useState(0);
  const [activeCarouselSlide, setActiveCarouselSlide] = useState(0);

  const tabsRefs = [];

  useEffect(() => {
    if (solutionId && scrollbar) {
      setActiveTab(parseInt(solutionId));
      setActiveCarouselSlide(0);
      console.log(scrollbar);

      scrollbar.scrollTo(
        0,
        tabsRefs[parseInt(solutionId)].current.offsetTop - 65,
        1000
      );
    }
  }, [scrollbar]);

  // ======================== Slider Settings
  const carouselSettings = {
    showStatus: false,
    showIndicators: false,
    showArrows: false,
    showThumbs: false,
    dynamicHeight: true,
    useKeyboardArrows: true,
    selectedItem: activeCarouselSlide,
  };

  const ArrowRight = ({ slidesCount }) => {
    return (
      <div
        className={`${styles.arrow} ${styles.arrowRight}`}
        onClick={() => changeSlide(slidesCount, "next")}
      >
        <img src={Arrow} alt="Arrow Right" />
      </div>
    );
  };

  const ArrowLeft = ({ slidesCount }) => {
    return (
      <div
        className={`${styles.arrow} ${styles.arrowLeft}`}
        onClick={() => changeSlide(slidesCount, "prev")}
      >
        <img src={Arrow} alt="Arrow Left" />
      </div>
    );
  };

  // ======================== Methods
  const changeSlide = (slidesCount, type) => {
    if (type === "next") {
      if (activeCarouselSlide < slidesCount - 1) {
        setActiveCarouselSlide(activeCarouselSlide + 1);
      }
    } else {
      if (activeCarouselSlide > 0) {
        setActiveCarouselSlide(activeCarouselSlide - 1);
      }
    }
  };

  const openTab = (index) => {
    if (index === activeTab) {
      setActiveTab(null);
    } else {
      setActiveTab(index);
    }
  };

  const tabs = solutions.map((item, index) => {
    tabsRefs[item.id] = createRef();

    return (
      <div
        className={`${styles.tab} ${
          activeTab === item.id ? styles.openTab : ""
        }`}
        key={item.id}
        ref={tabsRefs[item.id]}
      >
        <div className="container">
          <div className={styles.tabTitle} onClick={() => openTab(item.id)}>
            {item.title}
          </div>
        </div>
        <div
          className={`${styles.tabContent} ${
            activeTab === item.id ? styles.openTab : ""
          }`}
        >
          <div className="container">
            <p>{item.text}</p>
            {item.exploreURL && <a href={item.exploreURL}>Explore</a>}
          </div>
          {item.slides && (
            <div className="container">
              <div className={styles.slider}>
                <ArrowRight slidesCount={item.slides.length} />
                <ArrowLeft slidesCount={item.slides.length} />
                <Carousel {...carouselSettings}>
                  {item.slides.map((slide) => (
                    <div
                      key={slide.id}
                      className={`${styles.slide} ${
                        slide.type === "overlap"
                          ? styles.overlapSlide
                          : styles.normalSlide
                      }`}
                    >
                      <img
                        className={styles.slideImage1}
                        src={require(`@/assets/images/jpg/${slide.image1}`)}
                        alt="1"
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  });

  return <div className={styles.Tabs}>{tabs}</div>;
};

export default Tabs;
