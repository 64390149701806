import React from "react";
import styles from "./Form.module.scss";

// Animation
import { Slide, Fade } from "react-reveal";

// Assets
import logoDark from "@/assets/images/png/logo-dark.png";

const Form = () => {
  return (
    <Fade bottom delay={100}>
      <div className={styles.form}>
        <Slide bottom delay={200}>
          <Fade bottom delay={200}>
            <div className={styles.formHead}>
              <div className={styles.text}>
                <h1>GET A CALL</h1>
                <p>
                  Fill out your information and you will be contacted by our
                  team representatives shortly
                </p>
              </div>
              <div className={styles.logo}>
                <img src={logoDark} alt="Amstone Eg" />
              </div>
            </div>
          </Fade>
        </Slide>

        <Slide bottom delay={300}>
          <Fade bottom delay={300}>
            <form>
              <div className={styles.formGroup}>
                <label htmlFor="name">Name</label>
                <input type="text" id="name" />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="email">Email</label>
                <input type="email" id="email" />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="phone">Phone</label>
                <input type="tel" id="phone" />
              </div>
              <button>Send</button>
            </form>
          </Fade>
        </Slide>
      </div>
    </Fade>
  );
};

export default Form;
