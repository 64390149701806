import React from "react";
import styles from "./Consulting.module.scss";

// Componenets
import Header from "@/components/Consulting/Header";
import BannerOne from "@/components/Consulting/BannerOne";
import BannerTwo from "@/components/Consulting/BannerTwo";
import Sections from "@/components/Consulting/Sections";
import ClientChart from "@/components/Consulting/ClientChart";
import InternationalReach from "@/components/shared/InternationalReach";
import Footer from "@/components/shared/Footer";

// Hooks
import useSmoothScroller from "@/hooks/useSmoothScroller";

const Consulting = () => {
  useSmoothScroller();

  return (
    <div className="scroll-container">
      <div className={styles.Consulting}>
        <Header />
        <BannerOne />
        <Sections />
        <ClientChart />
        <BannerTwo />
        <InternationalReach />
        <Footer />
      </div>
    </div>
  );
};

export default Consulting;
