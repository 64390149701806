import React from "react";
import styles from "./InternationalReach.module.scss";
import { Link } from "react-router-dom";

// Animation
import { Slide, Fade } from "react-reveal";

// Data
import { contactUsInfo } from "@/data/contact-info";

// Assets
import bgVideo from "@/assets/videos/earth-technology-infographics-background.mp4";

const InternationalReach = () => {
  const countriesInfo = contactUsInfo.map((country, index) => (
    <Fade
      right
      distance={"50px"}
      delay={200 * (index + 1)}
      duration={200 * (index + 1)}
      key={country.id}
    >
      <Link to={`/contact-us/${country.id}`}>{country.country}.</Link>
    </Fade>
  ));

  return (
    <section className="page-part">
      <div className={styles.internationalReach}>
        <video autoPlay loop muted playsInline className={styles.bgVideo}>
          <source src={bgVideo} type="video/mp4" />
        </video>
        <div className={`container ${styles.wrapper}`}>
          <div className={styles.title}>
            <Slide bottom>
              <Fade bottom>INTERNATIONAL</Fade>
            </Slide>
            <Slide bottom delay={300}>
              <Fade bottom delay={300}>
                REACH
              </Fade>
            </Slide>
          </div>
          <div className={styles.text}>
            <Slide bottom delay={500}>
              <Fade bottom delay={500}>
                Amstone are International, we are wherever we need to be to best
                serve our clients. From our 3 main locations based centrally
                across the globe we can reach far and wide.
              </Fade>
            </Slide>
          </div>
          <div className={styles.locations}>{countriesInfo}</div>
        </div>
      </div>
    </section>
  );
};

export default InternationalReach;
