import React from "react";
import styles from "./Solutions.module.scss";

// Components
import Header from "@/components/Solutions/Header";
import Banner from "@/components/Solutions/Banner";
import Tabs from "@/components/Solutions/Tabs";
import Footer from "@/components/shared/Footer";
import useSmoothScroller from "@/hooks/useSmoothScroller";

const Solutions = () => {
  const { scrollbarI } = useSmoothScroller();

  return (
    <div className="scroll-container">
      <div className={styles.Solutions}>
        <Header />
        <Banner />
        <Tabs scrollbar={scrollbarI} />
        <Footer />
      </div>
    </div>
  );
};

export default Solutions;
