export const arrowDownIcon = (width = 15, height = 15) => (
  <svg
    width={width}
    height={height}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path
      d="M16.6402 8.26824L10.6402 13.2682C9.61589 14.1218 8.33552 12.5854 9.35982 11.7318L15.3598 6.7318C16.3841 5.87822 17.6645 7.41466 16.6402 8.26824Z"
      fill="currentColor"
    />
    <path
      d="M4.64019 6.7318L10.6402 11.7318C11.6645 12.5854 10.3841 14.1218 9.35982 13.2682L3.35982 8.26824C2.33552 7.41466 3.61589 5.87822 4.64019 6.7318Z"
      fill="currentColor"
    />
  </svg>
);

export const barsIcon = (width = 15, height = 15) => (
  <svg
    width={width}
    height={height}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384.97 384.97"
  >
    <g>
      <g id="Menu_1_">
        <path
          d="M12.03,120.303h360.909c6.641,0,12.03-5.39,12.03-12.03c0-6.641-5.39-12.03-12.03-12.03H12.03
			c-6.641,0-12.03,5.39-12.03,12.03C0,114.913,5.39,120.303,12.03,120.303z"
        />
        <path
          d="M372.939,180.455H12.03c-6.641,0-12.03,5.39-12.03,12.03s5.39,12.03,12.03,12.03h360.909c6.641,0,12.03-5.39,12.03-12.03
			S379.58,180.455,372.939,180.455z"
        />
        <path
          d="M372.939,264.667H132.333c-6.641,0-12.03,5.39-12.03,12.03c0,6.641,5.39,12.03,12.03,12.03h240.606
			c6.641,0,12.03-5.39,12.03-12.03C384.97,270.056,379.58,264.667,372.939,264.667z"
        />
      </g>
    </g>
  </svg>
);

export const musicOnIcon = (width = 15, height = 15) => (
  <svg
    width={width}
    height={height}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg" id="Line">
        <path
          d="m12.92 2.16a2 2 0 0 0 -2.08.15l-5.16 3.69h-1.68a3 3 0 0 0 -3 3v6a3 3 0 0 0 3 3h1.68l5.16 3.69a2 2 0 0 0 1.16.37 2 2 0 0 0 2-2v-16.12a2 2 0 0 0 -1.08-1.78zm-.92 17.9-5.42-3.87a1 1 0 0 0 -.58-.19h-2a1 1 0 0 1 -1-1v-6a1 1 0 0 1 1-1h2a1 1 0 0 0 .58-.19l5.42-3.87z"
          fill="currentColor"
        />
        <path
          d="m21.54 6.54a1 1 0 0 0 -1.37-.36 1 1 0 0 0 -.36 1.36 9 9 0 0 1 0 8.92 1 1 0 0 0 .36 1.36.93.93 0 0 0 .5.14 1 1 0 0 0 .87-.5 10.94 10.94 0 0 0 0-10.92z"
          fill="currentColor"
        />
        <path
          d="m16.71 8.15a1 1 0 0 0 -.36 1.37 5.06 5.06 0 0 1 0 5 1 1 0 0 0 .36 1.37 1 1 0 0 0 1.37-.37 7 7 0 0 0 0-7 1 1 0 0 0 -1.37-.37z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export const musicOffIcon = (width = 15, height = 15) => (
  <svg
    width={width}
    height={height}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg" id="Line">
        <path
          d="m12.92 2.16a2 2 0 0 0 -2.08.15l-5.16 3.69h-1.68a3 3 0 0 0 -3 3v6a3 3 0 0 0 3 3h1.68l5.16 3.69a2 2 0 0 0 1.16.37 2 2 0 0 0 2-2v-16.12a2 2 0 0 0 -1.08-1.78zm-.92 17.9-5.42-3.87a1 1 0 0 0 -.58-.19h-2a1 1 0 0 1 -1-1v-6a1 1 0 0 1 1-1h2a1 1 0 0 0 .58-.19l5.42-3.87z"
          fill="currentColor"
        />
        <path
          d="m21.41 12 1.3-1.29a1 1 0 0 0 -1.42-1.42l-1.29 1.3-1.29-1.3a1 1 0 0 0 -1.42 1.42l1.3 1.29-1.3 1.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l1.29-1.3 1.29 1.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export const closeIcon = (width = 15, height = 15) => (
  <svg
    width={width}
    height={height}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 371.23 371.23"
  >
    <polygon
      points="371.23,21.213 350.018,0 185.615,164.402 21.213,0 0,21.213 164.402,185.615 0,350.018 21.213,371.23 
	185.615,206.828 350.018,371.23 371.23,350.018 206.828,185.615 "
    />
  </svg>
);
