export const ourSolutionsData = [
  {
    id: 1,
    title: "COMBAT UAV & DRONES",
    tag: "LAND",
    image: "images/solutions/1.jpg",
  },
  {
    id: 2,
    title: "GUIDED & UNGUIDED SYSTEMS",
    tag: "AIR",
    image: "images/solutions/2.jpg",
  },
  {
    id: 3,
    title: "ROCKETS & MISSILES",
    tag: "LAND",
    image: "images/solutions/3.jpg",
  },
  {
    id: 4,
    title: "AIRCRAFTS & HELICOPTERS",
    tag: "LAND",
    image: "images/solutions/4.jpg",
  },
  {
    id: 5,
    title: "SPARE PARTS & MODERNISATION EQUIPMENT",
    tag: "AIR",
    image: "images/solutions/5.jpg",
  },
  {
    id: 6,
    title: "MANUFACTURING & ASSEMBLY LINES",
    tag: "AIR",
    image: "images/solutions/6.jpg",
  },
  {
    id: 7,
    title: "UPGRADING UNGUIDED LEGACY ROCKETS & WEAPONS",
    tag: "AIR",
    image: "images/solutions/7.jpg",
  },
  {
    id: 8,
    title: "SUPPORT & MAINTENANCE",
    tag: "LAND",
    image: "images/solutions/8.jpg",
  },
  {
    id: 9,
    title: "RESEARCH & DEVELOPMENT",
    tag: "LAND",
    image: "images/solutions/10.jpg",
    page: "mobile-detection",
  },
  {
    id: 10,
    title: "TRANSFER OF TECHNOLOGY SOLUTIONS",
    tag: "AIR",
    image: "images/solutions/9.jpg",
  },
];

export const solutions = [
  {
    id: 1,
    title: "COMBAT UAV & DRONES",
    text: "AIG provide many types of UAV & Drones with advanced unmanned equipment to serve the police, military, fire stations, rescue organizations, etc… Our Unmanned aerial vehicles can complete intelligence reconnaissance, relay communication, electronic countermeasure, precision strike, rapid rescue, firefighting, military training, and other tasks with the operational characteristics of multidimensional space, all- weather, asymmetric, non - contact, nonlinear, and zero casualties.Intelligent equipment will change the elements of warfare, combat concepts, organizational forms, and support modes, thereby promoting the evolution of warfare forms.",
    // exploreURL: "#",
    slides: [
      {
        id: 1,
        image1: "s1.jpg",
        type: "overlap",
      },
    ],
  },
  {
    id: 2,
    title: "GUIDED & UNGUIDED SYSTEMS",
    text: "AIG guided systems vary from self-guided that use many guidance and control mechanisms or a combination of two or more methods which improve accuracy and the chances of successful engagement. For unguided systems. AIG offer a wide range of systems, to attack or defend against land, sea and air targets.",
  },
  {
    id: 3,
    title: "ROCKETS & MISSILES",
    text: "AIG offers a wide range of rocket & missile systems that vary from small tactical use to much larger strategic warheads that have ranges of several hundred miles, with or without different accompanying launching platform options for air, sea and land and against all different types of threats.",
  },
  {
    id: 4,
    title: "AIRCRAFTS & HELICOPTERS",
    text: "AIG strives to provide the most efficient aircrafts & helicopters solutions to its customers who serve, protect, save lives and safely carry passengers in demanding environments. The company’s product line offers the full spectrum of new, used and repaired aircrafts & helicopters solutions for civil, government, military, law enforcement and other uses.",
  },
  {
    id: 5,
    title: "SPARE PARTS & MODERNISATION EQUIPMENT",
    text: "AIG got a thorough knowledge into almost all defense related spare parts and spare parts inventory management. Including which parts to stock, from where to source and in what volume. AIG offers top notch modernization for a large span of defense & weapon systems as a top priority for our customers, to increase reliability and mitigate safety concerns that older equipment presents and to adopt more modern technologies that support higher capabilities.",
  },
  {
    id: 6,
    title: "MANUFACTURING & ASSEMBLY LINES",
    text: "AIG and its subsidiary companies and partners own different manufacturing facilities & assembly lines all over the world that allow quality, speedy and flexible production of many civil, government, military, law enforcement products which can be customized to their needs",
  },
  {
    id: 7,
    title: "UPGRADING UNGUIDED LEGACY ROCKETS & WEAPONS",
    text: "Although most customers are facing challenges on the way to a legacy system upgrade, AIG can succeeded to offer a quite successful approaches in meeting them and have developed a range of effective projects and best practices to achieve our customer’s upgrade need.",
  },
  {
    id: 8,
    title: "SUPPORT & MAINTENANCE",
    text: "AIG is a world-class maintenance and support service provider in the defense sector. AIG offer a seamless and high-performing experience for our customers and utilize a proven methodologies to support and maintain existing weapons and defense systems owned by our customers.",
  },
  {
    id: 9,
    title: "RESEARCH & DEVELOPMENT",
    text: "AIG is a world-class maintenance and support service provider in the defense sector. AIG offer a seamless and high-performing experience for our customers and utilize a proven methodologies to support and maintain existing weapons and defense systems owned by our customers.",
  },
  {
    id: 10,
    title: "TRANSFER OF TECHNOLOGY SOLUTIONS",
    text: "AIG Transfer of Technology (ToT) combines research with real defense world products that can provide benefits and solutions to many customer problems. At the same time, it generates income and technical upgrades that can lead to further research and development. AIG is a trusted partner in ToT for beneficial for civil, government, military, law enforcement entities who can leverage outside expertise, and research.",
  },
];
