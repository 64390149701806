import React, { useState } from "react";
import styles from "./TopBar.module.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Properties
import properties from "@/properties.json";

// Assets
import { barsIcon, closeIcon } from "@/helpers/icons";

// Components
import MobileMenu from "../MobileMenu";

const TopBar = () => {
  const { loading } = useSelector((state) => state.appReducer);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div
      className={`${
        location.pathname === "/"
          ? styles.topbar
          : `${styles.topbar} ${styles.slideDown}`
      } ${!loading && styles.slideDown}`}
    >
      <div className="container">
        <ul className="hidden-md">
          <li>
            <NavLink to="/consulting">Consulting</NavLink>
          </li>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <img
              src={require(`@/assets/${properties.appearence.logo}`)}
              alt={properties.app_info.name}
              onClick={() => navigate("/")}
            />
          </li>
          <li>
            <NavLink to="/solutions">Solutions</NavLink>
          </li>
          <li>
            <NavLink to="/contact-us">Contact</NavLink>
          </li>
        </ul>
        <div className={`${styles.mobileBar} hidden-lg`}>
          <NavLink to="/">
            <img
              src={require(`@/assets/${properties.appearence.logo}`)}
              alt={properties.app_info.name}
            />
          </NavLink>

          <div
            className={styles.menuIcon}
            onClick={() =>
              openMobileMenu
                ? setOpenMobileMenu(false)
                : setOpenMobileMenu(true)
            }
          >
            {openMobileMenu ? closeIcon(20, 20) : barsIcon(25, 25)}
          </div>
          <MobileMenu
            openMobileMenu={openMobileMenu}
            setOpenMobileMenu={setOpenMobileMenu}
          />
        </div>
      </div>
    </div>
  );
};

export default TopBar;
